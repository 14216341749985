import { createSlice } from "@reduxjs/toolkit";
import { downloadIncrement } from "../middleware/downloadIncrement";

const initialState = {
  loading: false,
  error: "",
  downloadIncrement: [], 
};

const downloadIncrementSlice = createSlice({
  name: "downloadIncrement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadIncrement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadIncrement.fulfilled, (state, action) => {
      state.loading = false;
      state.getFolderContentDetail = action.payload.data || [];
    });
    
    builder.addCase(downloadIncrement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || "Something went wrong";
    });
  },
});

export default downloadIncrementSlice.reducer;
