import React, { useState } from 'react';
import Drawer from '../../Components/Drawer/Drawer';
import './Twiter_mastery.css';
import Instagramcompont from '../../Components/instagramcompont/Instagramcompont';
import Twiter from '../Twiter-card/Twiter';

function Twiter_mastry() {
  
  const [activeComponent, setActiveComponent] = useState("instagram");

  return (
    <>
     
      <div className="Twiter__OverAll_container">
        <div className="Twiter_mastry_Conatiner">
          <button
            className={`Insta_text_container ${
              activeComponent === 'instagram' ? 'active' : ''
            }`}
            onClick={() => setActiveComponent('instagram')}
          >
            Instagram
          </button>
          <button
            className={`Insta_text_container ${
              activeComponent === 'twitter' ? 'active' : ''
            }`}
            onClick={() => setActiveComponent('twitter')}
          >
            Twitter
          </button>
        </div>
        <div>
          {activeComponent === 'instagram' && <Instagramcompont />}
          {activeComponent === 'twitter' && <Twiter />}
        </div>
        {!activeComponent && <Instagramcompont />}
      </div>
    </>
  );
}

export default Twiter_mastry;
