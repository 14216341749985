import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const downloadIncrement = createAsyncThunk(
  "downloadIncrement",
  async ({ folderId, contentId, userId }) => { 
    try {
      console.log("downloadIncrement", folderId, contentId, userId);
      const res = await api.post(
        `${API_URL}/content/download-increment/${folderId}/${contentId}`, 
        { userId } 
      );
      console.log("Response", res);
      return res;
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  }
);
