import React, { useEffect, useRef, useState } from "react";
import "./ContentLibraryDetails.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFolderContentDetail } from "../../services/redux/middleware/getFolderContentDetail";
import { downloadIncrement } from "../../services/redux/middleware/downloadIncrement";
import { generatelink } from "../../services/redux/middleware/generatelink";

import { toast } from "react-toastify";
const ContentLibraryDetails = ({ closeDetails, id }) => {




  const dispatch = useDispatch();
  const { folder } = useParams();
  console.log("folder", folder)
  console.log("id", id)

  const detailData = useSelector((state) => state?.getFolderContentDetail);

  useEffect(() => {
    const folderId = folder;
    const contentId = id;
    dispatch(getFolderContentDetail({ folderId, contentId }));
  }, [dispatch, folder, id]);
  console.log("Detail:", detailData?.getFolderContentDetail)



  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-GB', { month: 'long' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
      (day % 10 === 2 && day !== 12) ? 'nd' :
        (day % 10 === 3 && day !== 13) ? 'rd' : 'th';
    return `${day}${suffix} ${month} ${year} - ${hours}:${minutes}`;
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  function formatSize(sizeInBytes) {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return `${Math.ceil(sizeInMB)}mb`;
  }



  // function handleDownload(fileUrl) {
  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.download = fileUrl.split("/").pop();
  //   link.target = "_blank";
  //   link.click();
  //   console.log("id for download", id)
  //   const folderId = folder;
  //   const contentId = id;
  //   const newuserId = localStorage.getItem("newuserId");
  //   const userId = JSON.parse(newuserId);
  //   const res = dispatch(downloadIncrement({ folderId, contentId, userId }));
  //   console.log("daata", res)
  // }
  // function handleDownload(fileUrl) {
  //   fetch(fileUrl, { mode: 'no-cors' })
  //     .then((response) => {
  //       const link = document.createElement('a');
  //       link.href = fileUrl;
  //       link.download = 'image.jpg';
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     })
  //     .catch((error) => console.error('Error:', error));
  // }
  const [isbuttonLoading, setisbuttonLoading] = useState(false);
  function handleDownload(fileUrl) {
    const data = { key: fileUrl }
    dispatch(generatelink(data))
      .then((result) => {

        console.log("Usman", result?.payload?.data?.signedUrl);

        setisbuttonLoading(true);

        fetch(result?.payload?.data?.signedUrl)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.blob();
          })
          .then((blob) => {
            const blobURL = window.URL.createObjectURL(blob);
            const aTag = document.createElement("a");
            aTag.href = blobURL;
            aTag.setAttribute("download", fileUrl.split("/").pop());
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
            setisbuttonLoading(false);
          })
          .catch((error) => {
            console.error("Download failed:", error);
          });
      })
      .catch((error) => {
        console.error('Error generating link:', error);
      });

    const folderId = folder;
    const contentId = id;
    const newuserId = localStorage.getItem("newuserId");
    const userId = JSON.parse(newuserId);
    const res = dispatch(downloadIncrement({ folderId, contentId, userId }));
    console.log("daata", res)

  }




  const [isHovered, setIsHovered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="ContentLibraryDetails_main">
      <div className="ContentLibraryDetails_main1">
        <div className="ContentLibraryDetails_main1_1">
          <img src="/Images/detailss/detail1.svg" alt=".." />
          <p className="ContentLibraryDetails_main1_1_ptag">{detailData?.getFolderContentDetail?.title}</p>
        </div>
        <img

          onClick={closeDetails}
          src="/Images/detailss/cross.svg"
          alt=".."
        />
      </div>

      {detailData?.getFolderContentDetail?.fileType?.startsWith("video/") ? (
        <div
          style={{ position: "relative", display: "inline-block" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <video
            ref={videoRef}
            src={detailData?.getFolderContentDetail?.image}
            className="video-element2"
            playsInline
            style={{
              width: "100%",
              display: "block",
              borderRadius: "10px",
            }}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
          ></video>

          {/* Show Play/Pause Button */}
          {(!isPlaying || isHovered) && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 2,
                cursor: "pointer",
              }}
            >
              <button
                style={{
                  background: "none",
                  border: "none",
                  padding: "0px",
                }}
                onClick={togglePlayPause}
              >
                <img
                  src={isPlaying ? "/Images/ContentLibraryImg/newpause.svg" : "/Images/ContentLibraryImg/newplay.svg"}
                  alt="Play/Pause"
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                />
              </button>
            </div>
          )}
        </div>

      ) : detailData?.getFolderContentDetail?.fileType?.startsWith("image/") ? (
        <img
          src={detailData?.getFolderContentDetail?.image}
          style={{ borderRadius: "10px", height: "300px", width: "100%", objectFit: "cover" }}
          alt="Content"
        />
      ) : (
        <p></p>
      )}


      <button className="ContentLibraryDetails_main_btn" onClick={() => handleDownload(detailData?.getFolderContentDetail?.image)}
      >

        {isbuttonLoading ? (
          <div className="loader">

            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            <p>Download Asset</p>

            <img src="/Images/detailss/downloadicon.svg" alt=".." /></div>
        )}</button>

      <div className="ContentLibraryDetails_mainDiv2">
        <p className="ContentLibraryDetails_mainDiv2ptag">File Details</p>

        <div className="ContentLibraryDetails_mainDiv2div">
          <p className="ContentLibraryDetails_mainDiv2div_ptag">Type</p>
          <p className="ContentLibraryDetails_mainDiv2div_ptag1">{detailData?.getFolderContentDetail?.fileType}</p>
        </div>

        <div className="ContentLibraryDetails_mainDiv2div">
          <p className="ContentLibraryDetails_mainDiv2div_ptag">Size</p>
          <p className="ContentLibraryDetails_mainDiv2div_ptag1">{formatSize(detailData?.getFolderContentDetail?.fileSize)}</p>
        </div>

        <div className="ContentLibraryDetails_mainDiv2div">
          <p className="ContentLibraryDetails_mainDiv2div_ptag">Updated</p>
          <p className="ContentLibraryDetails_mainDiv2div_ptag1">
            {formatDate(detailData?.getFolderContentDetail?.updatedAt)}
          </p>
        </div>
      </div>

      <p className="ContentLibraryDetails_main___p">Choose captions</p>




      <div className="ContentLibraryDetails_mainDiv3">
        <p className="ContentLibraryDetails_mainDiv3_P">
          {detailData?.getFolderContentDetail?.caption1}
        </p>
        <div className="ContentLibraryDetails_imgDiv">
          <img
            src="/Images/detailss/copyicon.svg"
            alt=".."
            onClick={() => handleCopy(detailData?.getFolderContentDetail?.caption1)}
            style={{ cursor: 'pointer', height: "24px", width: "24px" }}
          />
        </div>
      </div>

      <div className="ContentLibraryDetails_mainDiv3">
        <p className="ContentLibraryDetails_mainDiv3_P">
          {detailData?.getFolderContentDetail?.caption2}
        </p>
        <div className="ContentLibraryDetails_imgDiv">
          <img
            src="/Images/detailss/copyicon.svg"
            alt=".."
            onClick={() => handleCopy(detailData?.getFolderContentDetail?.caption2)}
            style={{ cursor: 'pointer', height: "24px", width: "24px" }}
          />
        </div>
      </div>

      <div className="ContentLibraryDetails_mainDiv3">
        <p className="ContentLibraryDetails_mainDiv3_P">
          {detailData?.getFolderContentDetail?.caption3}
        </p>
        <div className="ContentLibraryDetails_imgDiv">
          <img
            src="/Images/detailss/copyicon.svg"
            alt=".."
            onClick={() => handleCopy(detailData?.getFolderContentDetail?.caption3)}
            style={{ cursor: 'pointer', height: "24px", width: "24px" }}
          />
        </div>
      </div>

    </div>

  );
};

export default ContentLibraryDetails;