import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getDownloaded = createAsyncThunk("getDownloaded", async () => {
  try {
    const res = await api.get(`${API_URL}/content/contentLibrary?filter=most-downloaded`);
    console.log("API Response Download:", res);
    
    if (res) {
      return res;
    } else {
      console.error("getDownloaded:", res.data);
      return {
        status: 404,
        message: "getDownloaded",
      };
    }
  } catch (error) {
    console.error("Error fetching course:", error);
    return {
      message: error?.response?.data?.error || "Unknown error",
      status: error?.response?.status || 500,
    };
  }
});
