import React, { useState, useRef } from "react";
import "./AccountVerification.css";
import Form from "react-bootstrap/Form";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyemail } from "../../services/redux/middleware/verifyemail";
import { resendVerification } from "../../services/redux/middleware/resendVerification";
import { ToastContainer, toast } from 'react-toastify';
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
const AccountVerification = () => {
  const navigate=useNavigate();
  const [codee, setCodee] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const { email } = useParams();
  console.log(email)
  // Ensure inputRefs array has references for all inputs
  if (inputRefs.current.length !== codee.length) {
    inputRefs.current = Array(codee.length)
      .fill()
      .map((_, i) => inputRefs.current[i] || React.createRef());
  }
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (index, value) => {
    // Allow only alphanumeric characters
    const newValue = value.replace(/[^a-zA-Z0-9]/g, "").charAt(0);
    const newValues = [...codee];
    newValues[index] = newValue;
    setCodee(newValues);

    // Focus the next input field
    if (newValue && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const dispatch = useDispatch();
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !codee[index] && index > 0) {
      inputRefs.current[index - 1].current.focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    } else if (e.key === "ArrowRight" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].current.focus();
    }
  };

    const [isbuttonLoading, setIsButtonLoading] = useState(false);
  const handleVerify = async () => {
    const joinedCode = codee.join('');

    if (joinedCode.length < 4 || codee.includes("")) {
      toast.error("Enter the complete code");
      return;
    }

    const data = { email, code: joinedCode };
    console.log(data);
    setIsButtonLoading(true);
    try {
      const result = await dispatch(verifyemail(data));
      console.log(result);

      if (result?.payload?.status === 200) {
        setIsButtonLoading(false);
        setTimeout(() => {
          toast.success("Verification successful!");
        }, 100);
        navigate('/login');
      } else {
        setIsButtonLoading(false);
        setTimeout(() => {
          toast.error("Verification failed, please try again.");
        }, 100)

      }
    } catch (error) {
      setIsButtonLoading(false);
      console.error(error);
    }
  };

  const handleResendCode = async () => {
    try {
      const data = { email: email };
      const result = await dispatch(resendVerification(data));
      toast.success("Email Resend Successfully");
      console.log(result);
    } catch (error) {
      console.error("Error resending email:", error);
    }
  };
  

  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="SignUpMain">
          <img
            className="SignUpImg"
            src="/Images/SignupImg/hero.png"
            alt="Sign Up"
          />
          <div className="SignUpMain111">
            <div className="SignUpMain1111">
              <div className="SignUpMain1_111">
                <img
                  className="SignUpMain1_1iconn"
                  src="/Images/SignupImg/icon.svg"
                  alt="Icon"
                />
                <p className="SignUpMain1_1ptagg">Account Verification</p>
              </div>

              <div className="otpdiv">
                <Box
                  display="flex"
                  gap="16px"
                  mx="auto"
                  className="otpdiv22"
                  my={4}
                >
                  {codee.map((value, index) => (
                    <Form.Group key={index}>
                      <Form.Control
                        type="text"
                        className="email-divvv"
                        value={value}
                        maxLength={1}
                        ref={inputRefs.current[index]}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                      />
                    </Form.Group>
                  ))}
                </Box>
              </div>

              <button style={{ marginBottom: "17px"}} className="SignUpMain1_2Btn" onClick={handleVerify}>
                {isbuttonLoading ? (
                  <div className="loader">

                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <p>Verify Now</p>
                )}
              </button>
             

              <p className="requestagainPtag"  >Didn’t receive the code? <span className="requestagainPtagspan" onClick={handleResendCode}>Request again</span></p>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            progressStyle={{ background: '#0F6FFF' }}
          />

        </div>)}</>

  );
};

export default AccountVerification;