import React from "react";
import "./FunnelSecond.css";
import TrustedAgency from "../TrustedAgency/TrustedAgency";
import WinningProcess from "../WinningProcess/WinningProcess";
import SkainetPricing from "../SkainetPricing/SkainetPricing";
import Growing from "../Growing/Growing";
import NewFooter from "../NewFooter/NewFooter";
import Funnel from "../../Pages/Funnel_page/Funnel";
const FunnelSecond = () => {
  return (
    <>

    <div className="FunnelSecond-main">
      <div className="FunnelSecond-main-button-text-why">
        <button className="Dedicated-Marketing-Team">
          👥 Dedicated Marketing Team
        </button>
        <div className="tryy-funnel-heading-main">
          <h2 className="tryy-funnel-heading">
            WHY HIRE <span className="dificult-span">FULL-TIME EMPLOYEES </span>
          </h2>
          <h2 className="tryy-funnel-heading">WHEN YOU CAN GET YOUR OWN</h2>
          <h2 className="tryy-funnel-heading">
            <span className="dificult-span"> DEDICATED MARKETING TEAM?</span>
          </h2>
        </div>

        <p className="lorem-text-paragraph">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br />
          eiusmod tempor incididunt ut labore et dolore magna aliquaenim ad
          <br />
          minim veniam, quis.
        </p>
        <div className="completed-projects-main-part-all-main">
          <div className="completed-projects-main-part">
            <h2>50+</h2>
            <p>Project Completed</p>
          </div>
          <div className="completed-projects-main-part">
            <h2>100%</h2>
            <p>Client satisfaction </p>
          </div>
          <div className="completed-projects-main-part">
            <h2>10+</h2>
            <p>Years experience</p>
          </div>
        </div>
        <button className="book-a-call-button">Book a Call</button>
      </div>
      <div className="funnel-border-image-main">
        <img className="divider-align" src="/Images/FunnelSecond/bordercenter.svg" />
      </div>
      <div className="hiring-team-skainet-main">
        <div className="hiring-team-skainet-main-heading-left">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingTop: "46px",
            }}
          >
            <div>
              <h2>Hire The</h2>
              <h2>
                <span className="dificult-span">Skainet</span> Team
              </h2>
            </div>
            <div className="hiring-skainet-team-para">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing </p>
              <p> elit, sed do eiusmod tempor incididunt ut labore et</p>
              <p> dolore magna aliquaenim ad minim veniam, quis.</p>
            </div>
          </div>

          <div className="all-team-and-manager-main">
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>Success Manager</h3>
            </div>
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>Website Developer</h3>
            </div>
            <div style={{
              display:'flex',
              alignItems:'flex-start',
              flexDirection:'column' ,
              gap:'20px'
            }}>
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>Social Media Associate</h3>
            </div>
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>Media Buyer</h3>
            </div>
            </div>
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>Social Media Associate</h3>
            </div>
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>Cold Outreach Specialist</h3>
            </div>
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>SEO Specialist</h3>
            </div>
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>Graphic Designer & Video Animator</h3>
            </div>
            <div className="all-team-and-manager-main-heading-and-image">
             <img src="/Images/FunnelSecond/check.svg"/>
             <h3>Marketing Automation & Funnel Builder</h3>
            </div>
          </div>
        </div>
        <div >
          <img className="hiring-team-skainet-main-right" src="/Images/FunnelSecond/hiringteam.svg" />
        </div>
      </div>
    </div>
{/*    
    <TrustedAgency/>
    <WinningProcess/>
    <SkainetPricing/>
    <Growing/>
   
    <NewFooter/> */}
    </>
  );
};

export default FunnelSecond;
