import React, { useRef, useState } from "react";
import ResponsiveAppBar from "../../Components/Navbar/Appbar";
import Lines from "../../assets/div1lines.svg";
import DigitalCourses from "../../assets/Div2Image.svg";
// import InstagramMastery from "../../assets/div2box1.png";
import Slider from "react-slick";
// import TwitterMastery from "../../assets/div2box2.png";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/Rectangle 5330.png";
import image4 from "../../assets/Rectangle 5332.png";
import image5 from "../../assets/image_3.png";
import hoverBoxArrow from "../../assets/hoverBoxTop.svg";
import div3 from "../../assets/cards.png";
import CardNo from "../../assets/cardNo.svg";
import Final from "../../assets/FinalDiv.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import "./landingPage.css";
import NewFooter from "../../Components/NewFooter/NewFooter";
import SettingsIcon from "@mui/icons-material/Settings";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import bottomLine from "../../assets/bottomLineContext.svg"
import topLine from "../../assets/ToplineContent.svg"
import star2 from "../../assets/Star 2.png";
import Star4 from "../../assets/Star 4.png";
import { useNavigate } from "react-router-dom";


const LandingPage = () => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const navigate = useNavigate();
  const handleFullscreenToggle = () => {
    const videoContainer = videoRef.current.parentNode;
    if (!document.fullscreenElement) {
      videoContainer
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
          console.log("FullScreen")
        })
        .catch((err) => {
          console.error("Failed to enter fullscreen:", err);
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error("Failed to exit fullscreen:", err);
        });
    }
  };

  const settings = {
    infinite: false,
    speed: 5000,
    slidesToShow: 5, // Adjust the number of visible items
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false, // Remove left and right arrows
    pauseOnHover: false, // Prevent pausing on hover
  };

  const items = [
    { text: "INSTAGRAM COURSES", img: star2 },
    { text: "content libRARY", img: star2 },
    { text: "Digital academy", img: star2 },
    { text: "TWITTER COURSES", img: star2 },
    // { text: "PRICING", img: star2 },
  ];

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    videoRef.current.volume = newVolume;

    e.target.style.setProperty("--volume-fill", `${newVolume * 100}%`);
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  const [selectedCountry, setSelectedCountry] = useState('Select Country');

  const handleSelect = (eventKey) => {
    setSelectedCountry(eventKey);
  };

  return (
    <div className="landingPage">
      <ResponsiveAppBar />
      <div className="landingPage__topgradient">
        <div className="blurnessDiv1"></div>
        <div className="Landingpage-div1Bottom">
          {/* <div className="Blurness"></div> */}
          <div className="GlobalDiv1">
            <div className="globaldiv1Top">

              <p className="globalHeading">
                Transform Your <span className="globalHeadingBlue">Future</span><br /> with Sk<span className="globalHeadingBlue">ai</span>net Academy{" "}

              </p>
              <div className="globalPara-wrape">
                <p className="globalPara">
                  Your exclusive hub for cutting-edge courses, designed to empower your personal and professional growth.  </p>

              </div>
            </div>


          </div>
        </div>
        <div className="wrappingDiv2">
          <div className="whitelinesdiv">
            <div className="TopLine">
              <div className="scrolling-container">
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={star2} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>

                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={star2} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={star2} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
              </div>
            </div>
            <div className="bottomLine">
              <div className="scrolling-container">
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <img src={Star4} alt="star" />
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">INSTAGRAM COURSES </p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">content libRARY</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">Digital academy</p>
                <img src={Star4} alt="star" />

                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}
                <p className="TopLinePara">TWITTER COURSES</p>
                <img src={Star4} alt="star" />

                {/* <p className="TopLinePara">  PRICING </p> */}



              </div>
            </div>
            {/* <div className="TopLine"> */}
            {/* <Slider {...settings}>
              {items.map((item, index) => (
                <div key={index} className="carousel-item" style={{ display: "flex", alignItems: "center" }}>
                  <p className="TopLinePara">{item.text}</p>
                  <img src={item.img} alt="star" />
                </div>
              ))}
            </Slider> */}
            {/* </div> */}
            {/* <div className="bottomLine">
              <Slider {...settings}>
                {items.map((item, index) => (
                  <div key={index} className="carousel-item">
                    <div>


                      <p className="TopLinePara">{item.text}</p>
                    </div>
                    <div>

                      <img src={Star4} alt="star" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div> */}



          </div>

          <div className="div2" id="aboutus">
            <div className="blurdiv2"></div>
            <div className="div2TopHeading">
              <p className="Div2Heading">
                What is sk<span className="div2courses">ai</span>net academy?
              </p>
              <p className="div2topPara">
                Skainet Academy is an online learning platform built exclusively for the Skainet community. Designed for individuals who want to unlock their potential, Skainet Academy provides a library of expertly crafted courses covering topics like digital marketing, finance, trading, mindset, and more.  </p>
            </div>
            <div className="div2below">
              <div className="div2Belowleft">
                {/* <p className="div2Belowleft__heading">Launching January 1st, 2025, Skainet Academy begins with two powerful courses:</p> */}
                <div className="div2Box">
                  <img
                    className="InstaGramMastryImage"
                    src="/Images/LandingPage/icon.png"
                    alt=""
                  />
                  <div className="div2Belowbox">
                    <p className="Div2Box1Heading">Instagram Mastery</p>
                    <p className="div2box1para">
                      Build and grow your online presence.
                    </p>

                  </div>
                </div>
                <div className="div2Box">
                  <img
                    className="InstaGramMastryImage"
                    src="/Images/LandingPage/icon2.png"
                    alt=""
                  />
                  <div className="div2Belowbox">
                    <p className="Div2Box1Heading">X Mastery</p>
                    <p className="div2box1para">
                      Dominate X (formerly Twitter) with <br className="new" />strategies to influence and engage.
                    </p>

                  </div>
                </div>
              </div>
              <div className="div2BelowRight">
                <img src="/Images/LandingPage/Instaxicon.png" className="imageinstax" alt="Digital Courses" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="div3" id="services">
        <div className="div3__blurness__container"> </div>
        <div className="div3Top">
          <div className="div3Topleft">
            <p className="Div2Heading">
              What Do You <span className="div2courses"> Get? </span>{" "}
            </p>
            <p className="contentpara">
              By joining Skainet Academy, you’ll gain access to:
            </p>
          </div>
        </div>
        <div className="courses___cards__main__container">
          <div className="instgaram___mystery__card__courses">
            <img
              className="InstaGramMastryImage"
              src="/Images/LandingPage/icon.png"
              alt=""
            />
            <div className="instagram___mystery__course__text__container">
              <p className="instagram___mystery__course__text">
                Instagram <br /> Mastery
              </p>
              <p className="instagram___mystery__course__paragraph">
                Build and grow your online presence.
              </p>
            </div>
          </div>
          <div className="twitter___mystery__card__courses">
            <img
              className="InstaGramMastryImage"
              src="/Images/LandingPage/icon2.png"
              alt=""
            />
            <div className="twitter___mystery__course__text__container">
              <p className="twitter___mystery__course__text">X Mastery</p>
              <p className="twitter___mystery__course__paragraph">
                Dominate X (formerly Twitter) with strategies to influence and
                engage.
              </p>
            </div>
          </div>
          <div className="coaching___program__card__courses">
            <div className="comming__soon__container">
              <p className="comming__soon__text">COMING SOON</p>
            </div>
            <img
              className="InstaGramMastryImage"
              src="/Images/LandingPage/icon3.png"
              alt=""
            />
            <div className="coaching__course__text__container">
              <p className="coaching__course__text">Coaching Program</p>
              {/* <p className="coaching__course__paragraph">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accus.
              </p> */}
            </div>
          </div>
          <div className="coaching___program__card__courses">
            <div className="comming__soon__container">
              <p className="comming__soon__text">COMING SOON</p>
            </div>
            <img
              className="InstaGramMastryImage"
              src="/Images/LandingPage/icon4.png"
              alt=""
            />
            <div className="coaching__course__text__container">
              <p className="coaching__course__text">Live Programs</p>
              {/* <p className="coaching__course__paragraph">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accus.
              </p> */}
            </div>
          </div>
        </div>
      </div>


      <div className="benefits__div__wraperr">
        <div className="finalDiv">
          <div className="benefits__div__wraperr__bg_blur" > </div>
          <div className="BlurnessFinal"></div>
          <div className="finalheading">
            <p className="Div2Heading">
              Top 3 Benefits of Sk<span className="div2courses">ai</span>net Academy
            </p>
          </div>
          <div className="finalbottom">
            <div className="FINALBOTTOMLEFT">
              <div className="FinalBox1">
                <div className="finalBBoxUpper">
                  <p className="InstaFinal">Comprehensive Learning</p>
                  <p className="InstaFinalPara">
                    Access courses tailored for personal growth, business success, and financial literacy.{" "}
                  </p>
                </div>
                {/* <button className="PurchaseButton">Purchase Now</button> */}
              </div>

              <div className="FinalBox">
                <div className="finalBBoxUpper">
                  <p className="InstaFinal">Expert Guidance</p>
                  <p className="InstaFinalPara">
                    Each course is developed by industry professionals to ensure you gain actionable skills.{" "}
                  </p>
                </div>
                {/* <button className="PurchaseButton">Purchase Now</button> */}
              </div>

              <div className="FinalBox">
                <div className="finalBBoxUpper">
                  <p className="InstaFinal">Constant Updates</p>
                  <p className="InstaFinalPara">
                    Never fall behind. Skainet Academy delivers new courses and resources every month.{" "}
                  </p>
                </div>
                {/* <button className="PurchaseButton">Purchase Now</button> */}
              </div>
            </div>
            <div className="finalBottomright">
              <img src={Final} alt="Image" />
            </div>
          </div>
        </div>


      </div>
      <div className="wrappingDiv" id="testimonials">
        <div className="testimonials-container">
          <div className="Blurness"></div>
          <div className="finalheading">
            <p className="Div2Heading">
              Testimonials
            </p>
          </div>
          <p className="pricing__plan_sb_heading">Here’s what early members of Skainet are saying about our courses</p>
          <div className="testimonials-card-wraper">
            <div className="testimonials-card1">
              <img src="/Images/LandingPage/Star.svg" alt="star" />
              <div className="testimonials-card1-content-wrape">
                <p className="Sarah-text">Sarah T.</p>
                <p className="testimonial-card-sbtext">Skainet Academy's digital marketing courses significantly boosted my client base. Highly recommend for those looking to stay current in their industry!</p>

              </div>

            </div>
            <div className="testimonials-card1">
              <img src="/Images/LandingPage/Star.svg" alt="star" />
              <div className="testimonials-card1-content-wrape">
                <p className="Sarah-text">John D.</p>
                <p className="testimonial-card-sbtext">Skainet's Twitter strategy course gave me the tools to effectively engage my audience and drive traffic. Essential for building a professional network!"</p>
              </div>

            </div>
            <div className="testimonials-card1">
              <img src="/Images/LandingPage/Star.svg" alt="star" />
              <div className="testimonials-card1-content-wrape">
                <p className="Sarah-text">Mark S.</p>
                <p className="testimonial-card-sbtext">Thanks to the detailed insights from the Instagram and Twitter courses, I've seen a dramatic increase in my social media engagement. Skainet Academy truly delivers!</p>

              </div>

            </div>

          </div>


        </div>
        {/* <div className="div4">
          <div className="div4Top">
            <p className="Div2Heading">
              skainet <span className="div2courses">payment </span> gateway
            </p>
            <p className="div4topPara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              <br className="breakline4" />
              eiusmod tempor incididunt ut labore et dolore magna aliquaenim.
            </p>
          </div>
          <div className="div4below">
            <div className="blurness-center-bottom-"></div>
            <div className="div4Form">
              <div className="InputsDiv4">
                <div className="Input1">
                  <p className="InputFormpara">Name on Card</p>
                  <input className="NameInput" placeholder="Enter Name" />
                </div>
                <div class="Input1">
                  <p className="InputFormpara">Card Number</p>
                  <div class="input-wrapper">
                    <input
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                      id="cardNumber"
                      class="CardInput"
                      placeholder="1234 1234 1234 1234"
                    />
                    <img src={CardNo} alt="Card Icon" class="input-icon" />
                  </div>
                </div>
                <div className="formRow">
                  <div className="Input2">
                    <p className="InputFormpara">Expiry Date</p>
                    <input
                      className="NameInput"
                      type="text"
                      placeholder="Enter Date"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                  </div>
                  <div className="Input2">
                    <p className="InputFormpara">CVC</p>
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                      className="NameInput"
                      placeholder="Enter CVC"
                    />
                  </div>
                </div>
                <div class="Input1">
                  <p className="InputFormpara">Country</p>
                  <select id="country" class="custom-dropdown">
                    <option style={{ width: "10%" }} value="none">Select Country</option>
                    <option style={{ width: "100%" }} value="usa">United States</option>
                    <option style={{ width: "100%" }} value="uk">United Kingdom</option>
                    <option style={{ width: "100%" }} value="canada">Canada</option>
                    <option style={{ width: "100%" }} value="australia">Australia</option>
                  </select>
                </div>
                <div className="skainet__dropdown__uper-wrape">
                  <p className="skainet__dropdown__describtion">Country</p>
                  <Dropdown className="dropdown-skainet-academy">
                    <Dropdown.Toggle
                      className="Dropdown-menu-main-text"
                      variant="success"
                      id="dropdown-basic"
                    >
                      <p className="select__country__text">{selectedCountry}</p>
                      <img src="/Images/LandingPage/dropdown-arrow.svg" alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="dropdown-menu-lists-skainet-academy"
                      onSelect={handleSelect}
                    >
                      {['United States', 'United Kingdom', 'Canada', 'Australia'].map(
                        (country, index) => (
                          <Dropdown.Item
                            className={`menu__item1 ${selectedCountry === country ? 'active-item' : ''
                              }`}
                            key={index}
                            eventKey={country}
                            onClick={() => handleSelect(country)}
                          >
                            {country}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="Input1">
                  <p className="InputFormpara">ZIP</p>
                  <input
                    className="NameInput"
                    placeholder="Enter Zip"
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                </div>
              </div>
              <button className="NexTFormButton">Next</button>
            </div>
            <div className="div4right">
              <img src={div3} alt="CardNo" />
            </div>
          </div>
        </div> */}

        {/* <div className="finalDiv">
        <div className="BlurnessFinal"></div>
        <div className="finalheading">
          <p className="Div2Heading">
            lorem <span className="div2courses">ispum </span>{" "}
          </p>
        </div>
        <div className="finalbottom">
          <div className="FINALBOTTOMLEFT">
            <div className="FinalBox1">
              <div className="finalBBoxUpper">
                <p className="InstaFinal">instagram mastery</p>
                <p className="InstaFinalPara">
                  Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.{" "}
                </p>
              </div>
              <button className="PurchaseButton">Purchase Now</button>
            </div>

            <div className="FinalBox">
              <div className="finalBBoxUpper">
                <p className="InstaFinal">Twitter mastery</p>
                <p className="InstaFinalPara">
                  Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.{" "}
                </p>
              </div>
              <button className="PurchaseButton">Purchase Now</button>
            </div>

            <div className="FinalBox">
              <div className="finalBBoxUpper">
                <p className="InstaFinal">Marketing COntent</p>
                <p className="InstaFinalPara">
                  Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.{" "}
                </p>
              </div>
              <button className="PurchaseButton">Purchase Now</button>
            </div>
          </div>
          <div className="finalBottomright">
            <img src={Final} alt="Image" />
          </div>
        </div>
      </div> */}
      </div>
      <NewFooter />
    </div>
  );
};

export default LandingPage;