import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ContentLibrary.css";
import { getFolder } from "../../services/redux/middleware/getFolder";
import { useDispatch, useSelector } from "react-redux";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { Dropdown } from "react-bootstrap";


const ContentLibrary = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const FolderData = useSelector((state) => state?.getFolder);

  useEffect(() => {
    const fetchFolder = async () => {
      setIsLoading(true);
      await dispatch(getFolder());
      setIsLoading(false);
    };
    fetchFolder();
  }, [dispatch]);

  const handleFolderContent = (folder) => {
    console.log("Folder Id:", folder);
    navigate(`/content-library1/${folder}`);
  };


  const folderData = FolderData?.profile?.data?.contentLibrary || [];

  function formatDate(dateString) {
    if (!dateString) return "";

    const dateObj = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return dateObj.toLocaleDateString("en-US", options);
  }
  const [hoveredFolderId, setHoveredFolderId] = useState(null);

  const handleMouseEnter = (folderId) => {
    setHoveredFolderId(folderId);
  };

  const handleMouseLeave = () => {
    setHoveredFolderId(null);
  };
  let longPressTimer = null;
  const handleTouchEnd = () => {
    if (window.innerWidth <= 430) {
      clearTimeout(longPressTimer);
      longPressTimer = null;
    }
  };
  const handleTouchStart = (e, folderId) => {
    if (window.innerWidth <= 430) {
      longPressTimer = setTimeout(() => {
        handleMouseEnter(folderId);
      }, 500);
    }
  };

  useEffect(() => {
    const handleClickOutside = () => {
      handleMouseLeave();
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="Content">
          <div className="ContentLibraryTop">
            {folderData.map((folder, index) => (
              <div
                key={index}
                className="ContentLibraryTop_Div1"
                tabIndex="0"
                onMouseEnter={window.innerWidth > 430 ? () => handleMouseEnter(folder._id) : null}
                onMouseLeave={window.innerWidth > 430 ? handleMouseLeave : null}
                onTouchStart={(e) => handleTouchStart(e, folder._id)}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd}
                show={hoveredFolderId === folder._id}
                onClick={() => handleFolderContent(folder?._id)}
              >
                <div className="ContentLibraryTop_Div2">
                  <img src="/Images/ContentLibraryImg/file.svg" alt="files" />
                  <p className="ContentLibraryTop_Div2_p1">
                    {folder?.folderName}
                  </p>
                </div>
                <Dropdown
                  key={folder._id}
                  className="ddddrop"
                  show={hoveredFolderId === folder._id}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Dropdown.Toggle
                    className="dropdownmain11"
                    variant="success"
                    style={{
                      backgroundColor: "#393C43",
                      borderColor: "#393C43",
                      color: "white",
                    }}
                  />
                  <Dropdown.Menu
                    className="dropdownsetting"
                    style={{
                      backgroundColor: "#24262B",
                      padding: "15px 24px",
                      border: "1px solid #0F6FFF",
                      borderRadius: "8px",
                    }}
                  >
                    <Dropdown.Item
                      style={{ color: "white", padding: "0px", backgroundColor: "#24262B" }}
                      className="DropDownmenuOptionDivvvvv2"
                    >
                      <img src="/Images/ContentLibraryImg/totalfiles.svg" alt="" />
                      <p className="DropDownmenuOption2">
                        {folder?.contents?.length} Files
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: "white", padding: "0px", backgroundColor: "#24262B" }}
                      className="DropDownmenuOptionDivvvvv3"
                    >
                      <img src="/Images/ContentLibraryImg/timer.svg" alt="" />
                      <p className="DropDownmenuOption2">
                        Edited on {formatDate(folder?.updatedAt)}
                      </p>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ContentLibrary;
