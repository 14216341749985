import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProgress } from "../middleware/getProgress";

const initialState = {
  loading: false,
  error: "",
  getProgress: [],
};
const getProgressSlice = createSlice({
  name: "getProgress",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProgress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProgress.fulfilled, (state, action) => {
      state.loading = false;
      state.getProgress = action.payload;
    });
    builder.addCase(getProgress.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getProgressSlice.reducer;
