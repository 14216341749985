import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const updateProfile = createAsyncThunk(
  "updateProfile",
  async (data, { rejectWithValue }) => {
    try {

      const newuserId = localStorage.getItem("newuserId");
      const id = JSON.parse(newuserId);


      const res = await api.put(`${API_URL}/auth/update-informations/${id}`, data);
      console.log("res",res)
      return {
        status: res?.status,
        data: res?.data?.data,
        token: res?.data?.token,
      };
    } catch (error) {
      return rejectWithValue({
        message: error?.response?.data?.error || "Something went wrong",
        status: error?.response?.status || 500,
      });
    }
  }
);