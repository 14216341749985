import React, { useEffect, useState } from 'react'
import './ProfileView.css'
import { useDispatch, useSelector } from 'react-redux';
import { getProfileData } from "../../services/redux/middleware/getProfileData";
import { updateProfile } from "../../services/redux/middleware/updateProfile";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const ProfileView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const detailData = useSelector((state) => state?.getProfileData);
    const newuserId = localStorage.getItem("newuserId");
    const id = JSON.parse(newuserId);
    useEffect(() => {
        dispatch(getProfileData(id));
    }, [dispatch]);



    const [email, setEmail] = useState();
    const [fullName, setFullName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [referral, setReferral] = useState();
    const [isbuttonLoading, setisbuttonLoading] = useState(false);


    const [profileImage, setProfileImage] = useState();
    const [profileImageView, setProfileImageView] = useState();
    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const validImageTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/svg+xml',
                'image/webp',
                'image/bmp',
                'image/tiff'
            ];
            if (validImageTypes.includes(file.type)) {
                console.log("This is the selected image file:", file);
                setProfileImage(file);
                setProfileImageView(URL.createObjectURL(file));
            } else {
                toast.error("Please select a valid image file");
            }
        }
    };

    const handleUpdate = async () => {
        console.log("Update Data is:", profileImage, fullName, phoneNumber);

        const formData = new FormData();
        formData.append("fullName", fullName);
        formData.append("phoneNumber", phoneNumber);
        formData.append("profileImage", profileImage);

        setisbuttonLoading(true);
        await dispatch(updateProfile(formData)).then(async (res) => {

            console.log("Profile Update", res)
            if (res?.payload?.status === 200) {
                toast.success("Profile Update Sucessfully");
                setisbuttonLoading(false);
                const newuserId = localStorage.getItem("newuserId");
                const id = JSON.parse(newuserId);
                await dispatch(getProfileData(id));
                navigate("/instagramcourses")
            } else {
                toast.error("Something Went Wrong");
                setisbuttonLoading(false);
            }
        });
    }
    useEffect(() => {
        console.log("Detail for Profile:", detailData?.getProfileData?.data)
        if (detailData?.getProfileData?.data) {
            const data = detailData.getProfileData.data;
            setFullName(data.fullName || '');
            setEmail(data.email || '');
            setPhoneNumber(data.phoneNumber || '');
            setReferral(data.refferalLink || '');
            setProfileImage(data.profileImage || '');
        }
    }, [detailData]);


    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success("Text copied to clipboard!");
            })
            .catch((err) => {
                console.error("Error copying text: ", err);
            });
    };

    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className='profile-main-div'>
            <div className='profile-div'>
                <p className='profile-heading'>Profile</p>
                <p className='manage-heading'>Manage Profile</p>
            </div>
            <div className="profileImage-Upload">
                <div className="Profile-Image-div">
                    <img
                        src={profileImageView || profileImage}
                        alt=""
                        className={profileImageView || profileImage ? "profile-image2" : ""}
                    />
                </div>
                <label htmlFor="fileInput" className="camera-div">
                    <img
                        src="/Images/SignupImg/CameraDiv.svg"
                        alt="Camera"
                    />
                </label>
                <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                />
            </div>
            <div className='main-profile-content'>
                <div className='two-input-maindiv-profile'>
                    <div className="input-div-profile">
                        <p className="SignUpMain1_2_first_P">Full Name</p>
                        <div className="SignUpMain1_2_first_Divnew">
                            <img src="/Images/SignupImg/name.svg" alt="" className="image-icon" />
                            <input
                                type="text"
                                maxLength="20"
                                className="SignUpMain1_2_first_Div_T"
                                placeholder="Enter Full Name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="input-div-profile">
                        <p className="SignUpMain1_2_first_P">Email Address</p>
                        <div className="SignUpMain1_2_first_Divnew">
                            <img src="/Images/SignupImg/email.svg" alt="" className="image-icon" />
                            <input
                                type="text"
                                className="SignUpMain1_2_first_Div_T"
                                placeholder="Enter Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className='two-input-maindiv-profile'>
                    <div className="input-div-profile">
                        <p className="SignUpMain1_2_first_P">Phone Number</p>
                        <div className="SignUpMain1_2_first_Divnew">
                            <img src="/Images/SignupImg/Phone.svg" alt="" className="" />
                            <input

                                type="tel"
                                className="SignUpMain1_2_first_Div_T"
                                placeholder="Enter Phone Number"
                                value={phoneNumber}
                                pattern="[0-9]*"
                                maxLength="16"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\+?[0-9]*$/.test(value)) {
                                        setPhoneNumber(value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-div-profile">
                        <p className="SignUpMain1_2_first_P">Referral Link</p>
                        <div className="SignUpMain1_2_first_Divnew">
                            <img src="/Images/SignupImg/referal.svg" alt="" className="image-icon" />
                            <input
                                type="text"
                                className="SignUpMain1_2_first_Div_T"
                                placeholder="Enter Referral Link"
                                value={referral}
                                onChange={(e) => setReferral(e.target.value)}
                                disabled
                            />
                            <img src="/Images/SignupImg/copy.svg"
                                onClick={() => handleCopy(referral)}
                                alt="" className="image-icon" />

                        </div>
                    </div>
                </div>
                <button className='update-button-profile' onClick={handleUpdate} style={{ height: "56px" }}>
                    {isbuttonLoading ? (
                        <div className="loader" >

                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    ) : (
                        <p className='update-text'>Update</p>)}
                </button>

            </div>
        </div>
    )
}

export default ProfileView
