import React, { useState } from "react";
import "./Growing.css";

const Growing = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "LOREM IPSUM 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      features: [
        "Corem ipsum dolor",
        "Sit amet, consectetur adipiscing",
        "Nunc vulputate libero",
        "Et velit interdum",
      ],
    },
    {
      title: "LOREM IPSUM 2",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      features: [
        "Corem ipsum dolor",
        "Sit amet, consectetur adipiscing",
        "Nunc vulputate libero",
        "Et velit interdum",
      ],
    },
    {
      title: "LOREM IPSUM 3",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      features: [
        "Corem ipsum dolor",
        "Sit amet, consectetur adipiscing",
        "Nunc vulputate libero",
        "Et velit interdum",
      ],
    },
    {
      title: "LOREM IPSUM 4",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
      features: [
        "Corem ipsum dolor",
        "Sit amet, consectetur adipiscing",
        "Nunc vulputate libero",
        "Et velit interdum",
      ],
    },
    // Add more slides as needed
  ];

  const cardsPerSlide = 3; // Number of cards visible per slide
  const totalSlides = Math.ceil(slides.length / cardsPerSlide);

  const handleLeftArrowClick = () => {
    setCurrentSlide((prev) => (prev === totalSlides - 1 ? totalSlides - 1 : prev + 1));
  };

  const handleRightArrowClick = () => {
    setCurrentSlide((prev) => (prev === 0 ? 0 : prev - 1));
  };

  const getTranslateValue = () => {
    return `translateX(-${currentSlide * (100 / cardsPerSlide)}%)`;
  };

  return (
    <div
      className="WinningProcess-main-container"
      style={{
        background: "#00061B",
      }}
    >
      <div
        className="TrustedAgency-main-container-button-and-text2"
        style={{
          paddingLeft: "100px",
        }}
      >
        <div className="TrustedAgency-main-container-button-and-text-heading2">
          <h2>
            GROW <span className="dificult-span">Faster </span> than ever
          </h2>
          <div className="hiring-skainet-team-para">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do{" "}
            </p>
            <p> eiusmod tempor incididunt ut labore et dolore magna aliquaenim</p>
          </div>
        </div>
        <div className="arrow-left-right">
          <img
            src="/Images/Winning/leftarr.svg"
            alt="Left Arrow"
            onClick={handleLeftArrowClick}
          />
          <img
            src="/Images/Winning/rightarr.svg"
            alt="Right Arrow"
            onClick={handleRightArrowClick}
          />
        </div>
      </div>

      {/* Cards Container */}
      <div className="Growing-cards-slider">
        <div
          className="Growing-cards-container"
          style={{
            transform: getTranslateValue(),
          }}
        >
          {slides.map((slide, index) => (
            <div key={index} className="Growing-card">
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
              <div className="growing-image-and-text">
                {slide.features.map((feature, featureIndex) => (
                  <div key={featureIndex} className="lorem-growing-part">
                    <img src="/Images/Growing/chk.svg" alt="Check icon" />
                    <p>{feature}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Growing;
