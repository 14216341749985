import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getProgress = createAsyncThunk("getProgress", async (data) => {
  try {
    console.log("getProgress data",data);
    const res = await api.get(`${API_URL}/tracking/progress?userId=${data.userId}&courseId=${data.courseId}`);
    console.log("getProgressnew",res);


    return res;
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});