import React, { useState } from "react";
import "./ResetPassword2.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { changePassword } from "../../services/redux/middleware/changePassword";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
const ResetPassword2 = () => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const { email } = useParams();
  const dispatch = useDispatch();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const navigate = useNavigate();
  const [isbuttonLoading, setIsButtonLoading] = useState(false);

  const handleResetPassword = async () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


    if (!password1 || !password2) {
      toast.error("Please fill in all the fields");
      return;
    }


    if (password1 !== password2) {
      toast.error("Passwords do not match");
      return;
    }


    if (!passwordRegex.test(password1)) {
      toast.error(
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return;
    }

    setIsButtonLoading(true);

    const data = { email: email, newPassword: password1 };
    const res = await dispatch(changePassword(data));
    console.log(password1);
    console.log(res);

    if (res?.payload?.status === 200) {
      navigate('/login');
      setTimeout(() => {
        toast.success("Password Changed successfully!");
      }, 200);

      setIsButtonLoading(false);
    } else {
      toast.error("Error Occurred!");
      setIsButtonLoading(false);
    }
  };


  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="SignUpMainNext">
          <img className="SignUpImgNext" src="/Images/SignupImg/hero.png" alt="" />
          <div className="SignUpMain11Next">
            <div className="SignUpMain1Next">
              <div className="SignUpMain1_1Next">
                <img className="SignUpMain1_1iconNext" src="/Images/SignupImg/icon.svg" alt="...." />
                <p className="SignUpMain1_1ptagNext">Reset Password</p>
              </div>

              <div className="SignUpMain1_2Next">
                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">New Password</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/Siginskainet/pasword.svg" alt="...." className="image-icon" />
                    <input
                      type={showPassword1 ? "text" : "password"}
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Password"
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility1}
                      className="togglePasswordBtnNext"
                    >
                      {showPassword1 ? (
                        <img src="/Images/Siginskainet/show.svg" alt="Hide Password" />
                      ) : (
                        <img src="/Images/Siginskainet/hide.svg" alt="Show Password" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Confirm New Password</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/Siginskainet/pasword.svg" alt="...." className="image-icon" />
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Password"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility2}
                      className="togglePasswordBtnNext"
                    >
                      {showPassword2 ? (
                        <img src="/Images/Siginskainet/show.svg" alt="Hide Password" />
                      ) : (
                        <img src="/Images/Siginskainet/hide.svg" alt="Show Password" />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <button style={{ marginBottom: "17px", marginTop: "60px" }} className="SignUpMain1_2Btn" onClick={handleResetPassword}>
                {isbuttonLoading ? (
                  <div className="loader">

                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <p>Reset Password</p>
                )}
              </button>


            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            progressStyle={{ background: '#0F6FFF' }}
          />

        </div>)}</>
  );
};

export default ResetPassword2;
