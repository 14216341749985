import React from "react";
import "./SkainetPricing.css";
const SkainetPricing = () => {
  return (
    <div className="SkainetPricing-main-container">
        <div className="SkainetPricing-background-blur"></div>
      <div className="SkainetPricing-main-container-cards-main">
        <div className="SkainetPricing-main-container-cards-sub">
          <img src="/Images/Pricing/star.svg" />
          <div className="SkainetPricing-main-container-cards-sub-heading-text">
            <h2>Lorem ispum</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis dolor
              sit amet.
            </p>
          </div>
        </div>
        <div className="SkainetPricing-main-container-cards-sub">
          <img src="/Images/Pricing/star.svg" />
          <div className="SkainetPricing-main-container-cards-sub-heading-text">
            <h2>Lorem ispum</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis dolor
              sit amet.
            </p>
          </div>
        </div>
        <div className="SkainetPricing-main-container-cards-sub">
          <img src="/Images/Pricing/star.svg" />
          <div className="SkainetPricing-main-container-cards-sub-heading-text">
            <h2>Lorem ispum</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis dolor
              sit amet.
            </p>
          </div>
        </div>
      </div>
      <div className="skainet-pricing-plan-heading-color ">
        <h2>
          skainet <span className="dificult-span">Pricing </span> plan{" "}
        </h2>
        <div className="skainet-pricing-plan-heading-color-para">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
          <p>
            do eiusmod tempor incididunt ut labore et dolore magna aliquaenim.
          </p>
        </div>
      </div>
      <div className="skainet-pricing-plan-cards-main">
        <div  className="skainet-pricing-plan-cards-sub" style={{
          marginTop:'76.42px'
        }}>
          <button className="basic-btn">BASIC</button>
          <p>
            For all individuals and starters who want to start with domaining.
          </p>
          <div className="center-line-pricing-btn">
            <img src="/Images/Pricing/line.svg" />
          </div>

          <div className="doller-per-members">
            <h2>$19</h2>
            <p>Per member, per Month</p>
          </div>
          <div className="center-line-pricing-btn2">
            <img src="/Images/Pricing/line.svg" />
          </div>
          <div className="pricing-cards-bottom-context-plan-three-main">
            <div className="pricing-cards-bottom-context-plan">
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Corem ipsum dolor</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Sit amet, consectetur adipiscing</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Nunc vulputate libero</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/cross.svg" />
                <h2>et velit interdum</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/cross.svg" />
                <h2>Aliquet odio mattis. </h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/cross.svg" />
                <h2>Sit amet, consectetur</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>adipiscing elit nunc vu</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>Lputate liber</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>Velit interdum, acdh</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
            </div>
            <div className="free-trail-main-btn" id="startbtn-hovr">
              <button>Start 14 days free trials</button>
            </div>
          </div>
        </div>

        <div  style={{
          background:'#0F6FFFB2',
          border:'#FFFFFF33'
        }} className="skainet-pricing-plan-cards-sub">
          <button className="basic-btn">PROFESSIONAL</button>
          <p>
          For professional domain names
          investors with a big portfolio.
          </p>
          <div className="center-line-pricing-btn">
            <img src="/Images/Pricing/blueline.svg" />
          </div>

          <div className="doller-per-members">
            <h2 style={{
              color:'#FFFFFF'
            }}>$49</h2>
            <p>Per member, per Month</p>
          </div>
          <div className="center-line-pricing-btn2">
            <img src="/Images/Pricing/blueline.svg" />
          </div>
          <div className="pricing-cards-bottom-context-plan-three-main">
            <div className="pricing-cards-bottom-context-plan">
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Corem ipsum dolor</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Sit amet, consectetur adipiscing</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Nunc vulputate libero</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>et velit interdum</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Aliquet odio mattis. </h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Sit amet, consectetur</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>adipiscing elit nunc vu</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>Lputate liber</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>Velit interdum, acdh</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
            </div>
            <div className='free-trail-main-btn-new'>
          <button className="btn-styl">Start 14 days free trials</button>
         </div>
          </div>
        </div>

        <div className="skainet-pricing-plan-cards-sub" style={{
          marginTop:'76.42px'
        }}>
          <button className="basic-btn">ADVANCED</button>
          <p>For businessess, enterprise domain registrars and registries.</p>
          <div className="center-line-pricing-btn">
            <img src="/Images/Pricing/line.svg" />
          </div>

          <div className="doller-per-members">
            <h2>$99</h2>
            <p>Per member, per Month</p>
          </div>
          <div className="center-line-pricing-btn2">
            <img src="/Images/Pricing/line.svg" />
          </div>
          <div className="pricing-cards-bottom-context-plan-three-main">
            <div className="pricing-cards-bottom-context-plan">
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Corem ipsum dolor</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Sit amet, consectetur adipiscing</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Nunc vulputate libero</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>et velit interdum</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Aliquet odio mattis. </h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <img src="/Images/Pricing/chotacheck.svg" />
                <h2>Sit amet, consectetur</h2>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>adipiscing elit nunc vu</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>Lputate liber</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
              <div className="pricing-cards-bottom-context-plan-image-btn">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img src="/Images/Pricing/chotacheck.svg" />
                  <h2>Velit interdum, acdh</h2>
                </div>
                <div className="Coming-Soon">Coming Soon</div>
              </div>
            </div>
            <div className="free-trail-main-btn">
              <button>Start 14 days free trials</button>
            </div>
          </div>
        </div>
      </div>
      <div className="Payment-Methods-main-container">
        <h2>Payment Methods</h2>
        <div className="cards_div" style={{
          display:'flex',
          gap:'6px'
        }}>
          <img src="/Images/Pricing/visaa.svg"/>
          <img className="my-best-part-to-move" src="/Images/Pricing/bl.svg"/>
        </div>
        <p>We accept Visa, American Express, Mastercard, Paypal and<br></br> Crypto</p>
      </div>
    </div>
  );
};

export default SkainetPricing;
