import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProfileData } from "../middleware/getProfileData";

const initialState = {
  loading: false,
  error: "",
  getProfileData : [],
};
const getProfileDataSlice = createSlice({
  name: "getProfileData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.loading = false;      
      state.getProfileData = action.payload;
    });
    builder.addCase(getProfileData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default  getProfileDataSlice.reducer;