import React from "react";
import "./NewFooter.css";
import Logo from "../../assets/logo.svg";

const NewFooter = () => {
    return (
        <div className="Footer">
            <div className="Footer1">
                <div className="Footer2">
                    <div className="LogoFooter">
                        <img className="logo" src={Logo} alt="Logo" />
                    </div>
                    <p className="copyRight">Copyright 2025 © Skainet Academy</p>
                    <div className="social-links">
                        <a href="https://www.instagram.com/skainet_systems" className="social-link">Insta</a>
                        <span className="separator">·</span>
                        <a href="https://www.facebook.com/profile.php?id=100063529182444&__tn__=-UC*F" className="social-link">Facebook</a>
                        <span className="separator">·</span>
                        <a href="https://twitter.com/SkainetAI" className="social-link">Twitter</a>
                        <span className="separator">·</span>
                        <a href="https://t.me/skainetsystems" className="social-link">Telegram</a>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default NewFooter;
