import { configureStore } from "@reduxjs/toolkit";
// import profile from "./reducer/profile";
// import getUserDetail from "./reducer/getUserDetail";
// import getUserProfile from "./reducer/getUserProfile";
import getInstagramCourses from "./reducer/getInstagramCourses";
import getTwitterCourses from "./reducer/getTwitterCourses";
import getCourse from "./reducer/getCourse";
import getFolder from "./reducer/getFolder";
import getFolderContent from "./reducer/getFolderContent";
import getFolderContentDetail from "./reducer/getFolderContentDetail";
import updateProgress from "./reducer/updateProgress";
import getDownloaded from "./reducer/getDownloaded";
import downloadIncrement from "./reducer/downloadIncrement";
import getTrackingProgress from "./reducer/getTrackingProgress";
import getProgress from "./reducer/getProgress";
import getProfileData from "./reducer/getProfileData";

export const store = configureStore({
  reducer: {
    // profile: profile,
    // user: getUserDetail,
    // getProfile:getUserProfile
    getInstagramCourses: getInstagramCourses,
    getCourse: getCourse,
    getTwitterCourses: getTwitterCourses,
    getFolder: getFolder,
    getFolderContent: getFolderContent,
    updateProgress: updateProgress,
    getFolderContentDetail: getFolderContentDetail,
    getDownloaded: getDownloaded,
    downloadIncrement: downloadIncrement,
    getTrackingProgress: getTrackingProgress,
    getProgress:getProgress,
    getProfileData:getProfileData
  },
});