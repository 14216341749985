import React, { useRef, useState } from "react";
import Funnel_Navbar from "../../Components/Funnel_Navbar/Funnel_Navbar";
import "./Funnel.css";
import FunnelSecond from "../../Components/FunnelSecond/FunnelSecond";
import TrustedAgency from "../../Components/TrustedAgency/TrustedAgency";
import WinningProcess from "../../Components/WinningProcess/WinningProcess";
import Growing from "../../Components/Growing/Growing";

import SettingsIcon from "@mui/icons-material/Settings";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import NewFooter from "../../Components/NewFooter/NewFooter";
import SkainetPricing from "../../Components/SkainetPricing/SkainetPricing";

function Funnel() {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1); // Default volume is 100% (1.0)
  const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state

  const handleFullscreenToggle = () => {
    const videoContainer = videoRef.current.parentNode;
    if (!document.fullscreenElement) {
      videoContainer
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true); // Set fullscreen mode
        })
        .catch((err) => {
          console.error("Failed to enter fullscreen:", err);
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false); // Exit fullscreen mode
        })
        .catch((err) => {
          console.error("Failed to exit fullscreen:", err);
        });
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value); // Convert the value to a float
    setVolume(newVolume); // Update the React state
    videoRef.current.volume = newVolume; // Set video volume

    // Update CSS custom property for the range track background
    e.target.style.setProperty("--volume-fill", `${newVolume * 100}%`);
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  // Set the duration when the video metadata is loaded
  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  // Play/pause toggle
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Increment playback time by 10 seconds
  const incrementTime = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = Math.min(
        videoRef.current.currentTime + 10,
        duration
      );
    }
  };

  // Share logic (dummy function)
  const handleShare = () => {
    console.log("Share button clicked!");
  };

  // Format time (helper function)
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  return (
    <>
      <Funnel_Navbar />
      <div className="funneel-hero-top">
        <div className="video-funel-text-container">
          <div className="funnel-text-btn-container">
            <div className="funnel-text-over-all">
              <div className="Funnel_hello_text_container">
                <p className="Funnel-hello-text">👋 Hello! We are experts!</p>
              </div>

              <h1 className="funnel_text_h_Global">
                Global <span className="funnel_span_forex">Forex Trading</span>{" "}
                at a Glance
              </h1>
              <p className="funnel-text-P-align">
                Torem ipsum dolor sit amet, consectetur elit adipiscing. Nunc
                vulputate libero interdum.
              </p>
            </div>
            <div className="btn-container-funel-both">
              <button className="finnel-btn-align">Book a Call</button>
              <button className="finnel-btn-align2">Learn More</button>
            </div>
          </div>
          <div className="globaldivright">
            <div className="video-player-containerss">
              <video
                ref={videoRef}
                src="https://www.w3schools.com/html/movie.mp4"
                className="video-element"
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                onEnded={() => setIsPlaying(false)} // Reset play button when video ends
              ></video>

              <div
                className="controlss"
                style={{
                  position: "absolute",
                  bottom: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <div
                    className="video-play-and-stop-the-btn--main----side"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <div className="video-play-and-stop-the-btn">
                      <div className="button-and-time">
                        <button
                          className="playButton"
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={togglePlayPause}
                        >
                          {isPlaying ? (
                            <PauseIcon
                              style={{ color: "white", fontSize: "24px" }}
                            />
                          ) : (
                            <PlayArrowIcon
                              style={{ color: "white", fontSize: "24px" }}
                            />
                          )}
                        </button>
                        <p>
                          {formatTime(currentTime)}/{formatTime(duration)}
                        </p>
                      </div>
                      <div className="Other-video-icons">
                        <input
                          type="range"
                          className="volume-bar"
                          min="0"
                          max="1"
                          step="0.1"
                          value={volume}
                          onChange={handleVolumeChange}
                        />

                        <button
                          className="fullscreen-btn"
                          onClick={handleFullscreenToggle}
                        >
                          {isFullscreen ? (
                            <FullscreenExitIcon
                              style={{ color: "white", fontSize: "24px" }}
                            />
                          ) : (
                            <FullscreenIcon
                              style={{ color: "white", fontSize: "24px" }}
                            />
                          )}
                        </button>
                        <button className="settings-btn">
                          <SettingsIcon
                            style={{ color: "white", fontSize: "24px" }}
                          />
                        </button>
                      </div>

                      {/* <button className="settings-btn" onClick={openSettings}>
          <img src="/Images/DrawerImages/settings.svg" alt="Settings" />
        </button> */}
                    </div>

                    <div
                      className="progress-bar-container"
                      style={{
                        width: "95%",
                        height: "6px",
                        backgroundColor: "gray",
                        left: "15px",
                        position: "relative",
                        borderRadius: "7px",
                      }}
                    >
                      <div
                        className="progress-bar"
                        style={{
                          width: `${(currentTime / duration) * 100}%`,
                          height: "100%",
                          backgroundColor: "white",
                          borderRadius: "7px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <video id="my-video" class="video-js vjs-default-skin" controls>
    <source  src="https://www.w3schools.com/html/movie.mp4" type="video/mp4" />
</video> */}

            {/* <script>
    var player = videojs('my-video');
</script> */}
          </div>
        </div>
        <div></div>
        <div>
          <h2 className="funnel-text-trAined">
            100% Trained Virtual Assistanst
          </h2>
        </div>
        <div className="funel-logo-svg-contyainer">
          <img src="/Images/Fuunel-img/logo-ispum.svg" alt="logo" />
          <img src="/Images/Fuunel-img/Funel2ndlogo.svg" alt="logo" />
          <img src="/Images/Fuunel-img/logo3rd.svg" alt="logo" />
          <img src="/Images/Fuunel-img/logo4th.svg" alt="logo" />
          <img src="/Images/Fuunel-img/logo5th.svg" alt="logo" />
          <img src="/Images/Fuunel-img/logo3rd.svg" alt="logo" />
        </div>
      </div>

      <FunnelSecond />
      <TrustedAgency />
      <WinningProcess />
      <SkainetPricing />
      <Growing />
      <NewFooter />
    </>
  );
}

export default Funnel;
