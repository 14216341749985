import React from "react";
import "./TrustedAgency.css";
const TrustedAgency = () => {
  return (
    <div className="TrustedAgency-main-container">
      <div className="TrustedAgency-main-container-button-and-text">
        <div className="TrustedAgency-main-container-button-and-text-heading">
          <h2>
            Trusted <span className="dificult-span">50+</span> Agencies
          </h2>
          <div className="hiring-skainet-team-para">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do{" "}
            </p>
            <p>
              eiusmod tempor incididunt ut labore et dolore magna aliquaenim.
            </p>
          </div>
        </div>
        <button className="trusted-btn">View All</button>
      </div> 
      <div className="agency-members">
      <div className="member jimmy-carter">
        <img src="/Images/TrustedAgency/jimmy.svg" alt="Jimmy Carter" />
        <div className="info">
          <h3>Jimmy Carter</h3>
          <p>Co-founder Vizz</p>
        </div>
      </div>
      <div className="member k-jeni-albert">
        <img src="/Images/TrustedAgency/albert.svg" alt="K. Jeni Albert" />
        <div className="info">
          <h3>K. Jeni Albert</h3>
          <p>COO Weezab Labs</p>
        </div>
      </div>
      <div className="member johnny-lia">
        <img src="/Images/TrustedAgency/johny.svg" alt="Johnny Lia" />
        <div className="info">
          <h3>Johnny Lia</h3>
          <p>Founder SK Labs</p>
        </div>
      </div>
      <div className="member maxwell-lee">
        <img src="/Images/TrustedAgency/maxwell.svg" alt="Maxwell Lee" />
        <div className="info">
          <h3>Maxwell Lee</h3>
          <p>Marketing Officer Teli Co.</p>
        </div>
      </div>
      <div className="member albert-jaan">
        <img src="/Images/TrustedAgency/jaan.svg" alt="Albert Jaan" />
        <div className="info">
          <h3>Albert Jaan</h3>
          <p>Co-founder Razi Cabs</p>
        </div>
      </div>
      <div className="member keni-m">
        <img src="/Images/TrustedAgency/kini.svg" alt="Keni M." />
        <div className="info">
          <h3>Keni M.</h3>
          <p>Marketing Officer Gitlabs</p>
        </div>
      </div>
    </div>
  
    </div>
  );
};

export default TrustedAgency;
