import { createSlice } from "@reduxjs/toolkit";
import { getInstagramCourses } from "../middleware/getInstagramCourses";

const initialState = {
  loading: false,
  error: "",
  instagramCoursesData: [], // Renamed to match useSelector in the component
};

const getInstagramCoursesSlice = createSlice({
  name: "getInstagramCourses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInstagramCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInstagramCourses.fulfilled, (state, action) => {
      state.loading = false;
      state.instagramCoursesData = action.payload.data?.courses || [];
    });
    builder.addCase(getInstagramCourses.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || "Something went wrong";
    });
  },
});

export default getInstagramCoursesSlice.reducer;
