import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function Header({ headername }) {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(false);
  // const [userId, setUserId] = useState();
  // const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const notificationRef = useRef(null);
  // let audio = new Audio(notifysound);

  // const handleProfile = () => {
  //   console.log("profile clicked");
  //   navigate("/Profile");
  // };
  // useEffect(() => {
  //   const userid = localStorage.getItem("_id");
  //   console.log("user id ", userid);
  //   setUserId(userid);
  //   dispatch(getUserNotifications(userid));
  // }, [userId]);

  

  const notificationdata = useSelector(
    (state) => state?.getNotification?.myNotifications?.data
  );
  console.log("my notification is ", notificationdata);

  const Unreadnotification =
    notificationdata &&
    notificationdata?.some((item) => item && item?.NotifyRead === false);
  console.log("Unread Notifications ", Unreadnotification);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setNotification(false);
        console.log("my ref is", notificationRef.current)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="header-main">
        <p className="header-heading">{headername}</p>
      </div>
    </>
  );
}
