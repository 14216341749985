import { createSlice } from "@reduxjs/toolkit";
import { getTrackingProgress } from "../middleware/getTrackingProgress";

const initialState = {
  loading: false,
  error: "",
  getTrackingProgress: [],  // Correctly initialize the state to hold the data
};

const getTrackingProgressSlice = createSlice({
  name: "getTrackingProgress",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTrackingProgress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTrackingProgress.fulfilled, (state, action) => {
      state.loading = false;
      state.getTrackingProgress = action.payload.data; // Store the response data correctly
    });
    builder.addCase(getTrackingProgress.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default getTrackingProgressSlice.reducer;
