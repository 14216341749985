import React, { useEffect, useRef, useState } from "react";
import "./CourseOverview.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCourse } from "../../services/redux/middleware/getCourse";
import { updateProgress } from "../../services/redux/middleware/updateProgress";
import { getProgress } from "../../services/redux/middleware/getProgress";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import ClipLoader from "react-spinners/ClipLoader";

const CourseOverview = () => {
  useEffect(() => localStorage.setItem("SelectedOption", null))

  useEffect(() => {
    if (localStorage.getItem("navigatedFrom") === "/instagramcourses") {
      localStorage.setItem("SelectedOption", "Instagram");
    }

    else if (localStorage.getItem("navigatedFrom") === "/twittercourses") {
      localStorage.setItem("SelectedOption", "Twitter");
    }
    else {
      localStorage.removeItem("SelectedOption"); // Clear it if it doesn't match
    }

  });

  const [activeSections, setActiveSections] = useState([]);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  // console.log("this is plying",isPlaying)
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // Toggle play/pause functionality
  const togglePlayPause = () => {

    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);

    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const [watchedDuration, setWatchedDuration] = useState(0);
  const handleTimeUpdate = () => {
    const video = videoRef.current;
    setCurrentTime(video.currentTime);

    const currentTime = videoRef.current?.currentTime || 0;
    setWatchedDuration(Math.floor(currentTime));
  };


  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    setDuration(video.duration);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const handleVideoEnd = () => {
    setIsPlaying(false);
  };


  const incrementTime = () => {
    const video = videoRef.current;
    if (video.currentTime + 3 <= video.duration) {
      video.currentTime += 3;
      setCurrentTime(video.currentTime);
    }
  };

  const handleShare = () => {
    if (videoRef.current) {

      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }

  };


  const [isLooping, setIsLooping] = useState(false);

  const handleLoopToggle = () => {
    if (videoRef.current) {

      const newLoopStatus = !isLooping;
      setIsLooping(newLoopStatus);


      videoRef.current.loop = newLoopStatus;

      if (newLoopStatus) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    }
  };

  // const handleFullscreen = () => {
  //   if (videoRef.current) {

  //     if (videoRef.current.requestFullscreen) {
  //       videoRef.current.requestFullscreen();
  //     } else if (videoRef.current.mozRequestFullScreen) {
  //       videoRef.current.mozRequestFullScreen();
  //     } else if (videoRef.current.webkitRequestFullscreen) {
  //       videoRef.current.webkitRequestFullscreen();
  //     } else if (videoRef.current.msRequestFullscreen) {
  //       videoRef.current.msRequestFullscreen();
  //     } else if (videoRef.current.webkitEnterFullscreen) {
  //       videoRef.current.webkitEnterFullscreen();
  //     }
  //   }
  // };


  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = () => {
    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
      // For non-iOS and general devices
      if (videoRef.current && videoRef.current.parentNode.requestFullscreen) {
        videoRef.current.parentNode.requestFullscreen();
        setIsFullscreen(true);
      }
      // For iOS devices (Safari on iPhone)
      else if (videoRef.current && videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
        setIsFullscreen(true);
      }
    } else {
      // Exit fullscreen for both non-iOS and iOS
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };


  useEffect(() => {
    // Listen for changes to fullscreen state
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false); // Reset fullscreen state
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);


  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const handleCanPlay = () => {
    setIsVideoLoading(false);
  };

  const handleWaiting = () => {
    setIsVideoLoading(true);
  };

  const courseState = useSelector((state) => state?.getCourse);

  const { courseId } = useParams();
  const dispatch = useDispatch();

  const [isSubsectionOpen, setIsSubsectionOpen] = useState(false);
  const toggleSubsection = (index) => {
    setIsSubsectionOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };



  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('newuserId'))
    const dataa = { courseId, userId }
    dispatch(getCourse(dataa));
  }, [dispatch, courseId]);




  const [videoLink, setVideoLink] = useState("");
  const [sectionWatchId, setsectionWatchId] = useState("");
  const [subSectionWatchId, setsubSectionWatchId] = useState("");
  const [sectionVideo, setSectionVideo] = useState();


  const handleVideo = (videoPath, subsectionId, sectionId) => {
    if (!videoPath) {
      console.error("Invalid video path:", videoPath);
      return;
    }
    setVideoLink("");
    setTimeout(() => {
      setVideoLink(videoPath);
    }, 0);
    setsectionWatchId(sectionId);
    setsubSectionWatchId(subsectionId);
    console.log("Video Path:", videoPath);
    console.log("Subsection ID:", subsectionId);
    console.log("Section ID:", sectionId);
  };

  const handleVideoSection = (videoPathSection) => {
    if (!videoPathSection) {
      console.error("Invalid video path for section:", videoPathSection);
      return;
    }
    setVideoLink("");
    setTimeout(() => {
      setVideoLink(videoPathSection);
    }, 0);
    setSectionVideo(videoPathSection);
    console.log("Section Video Path:", videoPathSection);
    console.log("Section Video Path in State:", sectionVideo);
  };


  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);



  const getProgressData = useSelector((state) => state?.getProgress);

  const [videoCheck, setVideoCheck] = useState();
  const userId = JSON.parse(localStorage.getItem('newuserId'));
  const fetchProgress = async () => {
    const data = { userId, courseId };
    console.log("Fetching data:", data);
    try {
      await dispatch(getProgress(data));
    } catch (error) {
      console.error("Error dispatching getTrackingProgress:", error);
    }
  };

  useEffect(() => {
    fetchProgress();
  }, []);

  useEffect(() => {
    if (getProgressData?.getProgress?.data?.progress?.sections) {
      setVideoCheck(getProgressData.getProgress.data.progress.sections);
    }
  }, [getProgressData]);

  useEffect(() => {
    console.log("Fetched progress:", videoCheck);
  }, [videoCheck]);

  const handleVideoWatch = async () => {
    console.log("hhhhhhhh", courseId, sectionWatchId, subSectionWatchId, watchedDuration);

    const newuserId = localStorage.getItem("newuserId");
    const userId = JSON.parse(newuserId);
    const data = {
      userId: userId,
      courseId: courseId,
      sectionId: sectionWatchId,
      subSectionId: subSectionWatchId,
      watchedDuration: watchedDuration
    };
    console.log("this is data consoling", data);

    dispatch(updateProgress(data)).then((res) => {
      console.log("this is response", res);
      fetchProgress();
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying) {
        handleVideoWatch();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [isPlaying, courseId, sectionWatchId, subSectionWatchId, currentTime]);


  const [videoLinkNew, setVideoLinkNew] = useState();
  useEffect(() => {
    if (
      courseState?.getCourse?.sections?.[0]?.subSections?.[0]?.content?.[0]
    ) {
      console.log(
        "pasha",
        courseState?.getCourse?.sections[0]?.content
      );
      setVideoLinkNew(courseState?.getCourse?.sections[0].content);
      console.log("dgfdg", videoLinkNew);
    }
  }, [courseState]);


  useEffect(() => {
    handleVideoWatch();
  }, [isPlaying])


  function formatDuration(duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    if (hours > 0) {
      return `${hours} hr ${minutes} min ${seconds} sec`;
    } else if (minutes > 0) {
      return `${minutes} min ${seconds} sec`;
    } else {
      return `${seconds} sec`;
    }
  }


  useEffect(() => {
    if (videoRef.current && videoLink) {
      const video = videoRef.current;
      setIsPlaying(true);
      video.load();
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log("Video is playing");
          })
          .catch((error) => {
            console.error("Error playing the video:", error);
          });
      }
    }
  }, [videoLink]);

  const [visibleSections, setVisibleSections] = useState(5);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggleSections = () => {
    if (isExpanded) {
      setVisibleSections(5);
    } else {
      setVisibleSections(courseState?.getCourse?.sections?.length);
    }
    setIsExpanded(!isExpanded);
  };


  const [activeSection, setActiveSection] = useState(null);
  const [activeSubsection, setActiveSubsection] = useState(null);

  const [isIphone, setIsIphone] = useState(false);
  useEffect(() => {
    // Check if the device is an iPhone based on user agent
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('iphone')) {
      setIsIphone(true);
    }
  }, []);
  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (

        <div className="CourseOverview-main-container">
          <div className="Course-Overview-heading-part">
            <h2>Course Overview</h2>
            <p>{courseState?.getCourse?.courseType}</p>
          </div>
          <div className="overview-video-and-course-content-main">

            <div className="overview-video-and-course-content-main-left">
              <div className={`video-player-container ${isFullscreen ? "fullscreen" : ""}`} style={{ position: "relative" }}>
                {isVideoLoading && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1,
                    }}
                  >
                    <ClipLoader color="#0F6FFF" size={50} />
                  </div>
                )}
                <video
                  ref={videoRef}
                  src={videoLink || videoLinkNew}
                  className="video-element2"
                  onTimeUpdate={handleTimeUpdate}
                  onLoadedMetadata={handleLoadedMetadata}
                  onEnded={handleVideoEnd}
                  onCanPlay={handleCanPlay}
                  onWaiting={handleWaiting}
                  onDoubleClick={handleFullscreen}
                  controls={false}
                  playsInline
                ></video>
                <div className="controls">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div className="progres-again-try">
                      <div
                        className="progress-bar-container"
                        style={{
                          width: "100%",
                          height: "3px",
                          backgroundColor: "#ccc",
                          left: '8px',
                          position: "relative",
                        }}
                      >
                        <div
                          className="progress-bar"
                          style={{
                            width: `${(currentTime / duration) * 100}%`,
                            height: "100%",
                            backgroundColor: "#EF4444",
                            borderRadius: "4px",
                          }}
                        ></div>
                      </div>
                      <div className="video-play-and-stop-the-btn--main----side">
                        <div className="video-play-and-stop-the-btn2">
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              padding: "0px"
                            }}
                            onClick={togglePlayPause}
                          >
                            <img className="mini-pause-use-in-med"
                              src={
                                isPlaying
                                  ? "/Images/DrawerImages/play.svg"
                                  : "/Images/DrawerImages/pause.svg"
                              }
                              alt="Play/Pause"
                            />
                          </button>
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              padding: "0px"
                            }}
                            onClick={incrementTime}
                          >
                            <img className="mini-pause-use-in-med"
                              src="/Images/DrawerImages/pause2.svg"
                              alt="Increment"
                            />
                          </button>
                          <button
                            style={{
                              background: "none",
                              border: "none",
                              padding: "0px"
                            }}
                            onClick={handleShare}
                          >
                            <img className="mini-pause-use-in-med" src="/Images/DrawerImages/ref.svg" alt="Share" />
                          </button>

                          <p className="new------------again-stye">
                            {formatTime(currentTime)}/{formatTime(duration)}
                          </p>
                        </div>
                        <div className="video-play-and-stop-the-btn--main----side-left">
                          <img
                            className="mini-pause-use-in-med"
                            src={isLooping ? "/Images/DrawerImages/leftd11.svg" : "/Images/DrawerImages/leftd1.svg"} // Change image based on loop state
                            alt="Left Button 1"
                            onClick={handleLoopToggle}
                            style={{ cursor: "pointer" }}
                          />

                          {!isIphone && (
                            <img
                              className="mini-pause-use-in-med"
                              src="/Images/DrawerImages/leftd3.svg"
                              alt="Left Button 3"
                              onClick={handleFullscreen}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="overview-video-and-course-content-main-left-bottom">
                <h2
                  className="Instagram-Mastery"
                  style={{
                    marginTop: "24px",
                  }}
                >

                  {courseState?.getCourse?.title}
                </h2>
                <div className="overview-video-and-course-content-main-left-bottom-overview">
                  <div className="overview-video-and-course-content-main-left-bottom-overview-first">
                    <h2
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      Overview
                    </h2>
                    <p
                      className="using-for-monst-family break-word-content"
                      style={{
                        color: "#9EA0A5",
                      }}
                      dangerouslySetInnerHTML={{ __html: courseState?.getCourse?.description }} />

                  </div>
                  <div className="overview-video-and-course-content-main-left-bottom-overview-first">
                    <h2>What You'll Learn:</h2>

                    <p
                      className="using-for-monst-family break-word-content"
                      style={{
                        color: "#9EA0A5",
                      }}
                      dangerouslySetInnerHTML={{ __html: courseState?.getCourse?.whatYouWillLearn }} />

                  </div>
                  <div className="overview-video-and-course-content-main-left-bottom-overview-first">
                    <h2>Why Take This Course:</h2>


                    <p
                      className="using-for-monst-family break-word-content"
                      style={{
                        color: "#9EA0A5",
                      }}
                      dangerouslySetInnerHTML={{ __html: courseState?.getCourse?.whyTakeThisCourse }} />

                  </div>


                  <div className="overview-video-and-course-content-main-left-bottom-overview-first">
                    <h2>Who Should Enroll:</h2>
                    <p
                      className="using-for-monst-family break-word-content" style={{
                        color: "#9EA0A5",
                      }}
                      dangerouslySetInnerHTML={{ __html: courseState?.getCourse?.whoShouldEnroll }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="overview-video-and-course-content-main-right">
              <div className="Course-Content-and-lecture">
                <h2>Course Content</h2>
                <p>
                  Courses (
                  {courseState?.getCourse?.sections?.reduce(
                    (total, section) => total + (section?.subSections?.length || 0),
                    0
                  )}
                  ) Total{" "}
                  {formatDuration(
                    courseState?.getCourse?.sections?.reduce(
                      (total, section) =>
                        total +
                        section?.duration +
                        section?.subSections?.reduce(
                          (subTotal, subsection) => subTotal + (subsection?.duration || 0),
                          0
                        ),
                      0
                    )
                  )}
                </p>

                <hr className="hrblueline" />
              </div>


              {courseState?.getCourse?.sections?.slice(0, visibleSections)?.map((section, index) => (
                <div key={index}>
                  <div style={{ marginBottom: "10px" }}>
                    <div>
                      <div className="section-div">
                        <p
                          className="sectionnn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setActiveSection(index);
                            setActiveSubsection(null);
                            handleVideoSection(section?.content);
                          }}
                        >
                          Section {index + 1} | {section?.title}  <span className="sectionnn">| {formatDuration(section?.duration)}</span>
                        </p>

                        <img
                          src={
                            isSubsectionOpen[index]
                              ? "/Images/Dashboard/dropdownnn.svg"
                              : "/Images/Dashboard/dropdown2.svg"
                          }
                          onClick={() => toggleSubsection(index)}
                          alt="Dropdown Icon"
                        />
                      </div>
                    </div>
                    <div
                      className="play-div"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setActiveSection(index);
                        setActiveSubsection(null);
                        handleVideoSection(section?.content);
                      }}
                    >
                      <img src="/Images/Dashboard/playy.svg" alt="Play Icon" />
                      <p className="minvid">
                        {section?.subSections?.length || 0} video |{" "}
                        {formatDuration(
                          section?.subSections?.reduce(
                            (total, subsection) => total + (subsection?.duration || 0),
                            0
                          )
                        )}
                      </p>
                    </div>
                  </div>
                  {/* Subsections */}
                  {isSubsectionOpen[index] &&
                    section?.subSections?.map((subsection, idx) => {

                      const matchedSection = videoCheck?.find(
                        (v) => v.sectionId === section._id
                      );
                      const matchedSubsection = matchedSection?.subSections?.find(
                        (sub) => sub.subSectionId === subsection._id
                      );
                      const isCompleted = matchedSubsection?.isCompleted || false;


                      const progressIcon = idx === 0
                        ? isCompleted
                          ? "/Images/Dashboard/Progress6.svg"
                          : "/Images/Dashboard/Progress3.svg"
                        : isCompleted
                          ? "/Images/Dashboard/Progress1.svg"
                          : "/Images/Dashboard/Progress5.svg";


                      return (
                        <div key={idx} className="subsection-divv">
                          <img
                            src={progressIcon}
                            alt="Progress Icon"
                            style={{ marginTop: "-2px" }}
                          />
                          <div
                            className={`${idx === 0 ? "subsection---" : "subsection---2"
                              }`}
                          >
                            <p className="getting">{subsection?.title}</p>
                            <div
                              className="submaindiv"
                              onClick={() => {
                                setActiveSection(index);
                                setActiveSubsection(subsection?._id);
                                handleVideo(
                                  subsection?.content,
                                  subsection?._id,
                                  section?._id
                                );
                              }}
                            >
                              <img
                                src={
                                  activeSubsection === subsection?._id
                                    ? "/Images/Dashboard/Playblue.svg"
                                    : "/Images/Dashboard/play2.svg"
                                }
                                alt="Play Icon"
                              />
                              <p
                                className="video--text"
                                style={{
                                  color:
                                    activeSubsection === subsection?._id
                                      ? "#0F6FFF"
                                      : "#9EA0A5",
                                }}
                              >
                                1 Video | {formatDuration(subsection?.duration)}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <hr className="hrblueline2" />
                </div>
              ))}


              {courseState?.getCourse?.sections?.length > 5 && (
                <button className="bottom-button" onClick={handleToggleSections}>
                  {isExpanded ? "Load Less" : "Load More"}
                </button>
              )}
            </div>
          </div>
        </div>)}
    </>
  );
};

export default CourseOverview;