import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getTrackingProgress = createAsyncThunk(
  "getTrackingProgress",
  async ({ userId, courseId }, { rejectWithValue }) => {
    try {
      const res = await api.get(`${API_URL}/tracking/progress`, {
        data: { 
          userId, 
          courseId 
        } 
      });
console.log("dataaaa",userId,courseId)
      console.log("API Response:", res);

      if (res.data) {
        return {
          status: res.status,
          data: res.data,
        };
      } else {
        console.error("getTrackingProgress:", res.data);
        return rejectWithValue({
          status: 404,
          message: "Data not found",
        });
      }
    } catch (error) {
      console.error("Error fetching tracking progress:", error);
      return rejectWithValue({
        message: error?.response?.data?.error || "Unknown error",
        status: error?.response?.status || 500,
      });
    }
  }
);
